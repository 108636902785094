<template>
  <div>
    <div v-if="!pageIndexApiDataIsLoading">

      <company-licence-component/>

      <div class="row">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/admin"><i class="fa fa-cogs fa-fw fa-lg"></i></router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/products"><i class="fa fa-cubes fa-fw fa-lg"></i> Gestion
            des Articles
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/clients"><i class="fa fa-users fa-fw fa-lg"></i> Gestion
            des Clients
          </router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/admin/exports"><i
              class="fa fa-file-download fa-fw fa-lg text-orange"></i> Export des Données
          </router-link>
        </div>
      </div>

      <!--<h1 class="page-header">Exports Excel<small class="lead"> Exportez vos données au format Excel pour une analyse personnalisée.</small></h1>-->
      <hr class="bg-grey-lighter"/>

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/admin/exports"><i class="fa fa-file-pdf fa-fw fa-lg"></i> PDF
          </router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/admin/exports/excel"><i
              class="fa fa-file-excel fa-fw fa-lg text-orange"></i> Excel
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/exports/csv"><i class="fa fa-file fa-fw fa-lg"></i> CSV
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/exports/custom"><i class="fa fa-download fa-fw fa-lg"></i>
            Autres
          </router-link>
        </div>
      </div>


      <div v-if="pageIndexApiData != null">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="panel">
              <div class="panel-body">
                <div>
                  <h2 v-if="pusherPayload.status == 'loading'"><i class="fas fa-spinner fa-pulse"></i> Chargement en
                    cours ...</h2>
                  <h2 v-else>Export au format Excel</h2>
                  <span v-if="documentDownloadUrlApiDataErrorMessage != null">
                                        <small
                                            class="lead text-danger">{{ documentDownloadUrlApiDataErrorMessage }}</small><br/>
                                    </span>
                  <span v-if="documentDownloadUrlApiDataErrorMessage == null && pusherPayload.message != ''">
                                        <small class="lead"
                                               :class="{'text-success':pusherPayload.status == 'success','text-warning':pusherPayload.status == 'warning','text-danger':pusherPayload.status == 'error',}">{{ pusherPayload.message }}</small><br/>
                                    </span>
                  <br/>
                  <div v-if="pusherPayload.download_url">
                    <a class="btn btn-xl btn-danger" :href="pusherPayload.download_url" target="_blank"><i
                        class="fa fa-download fa-fw fa-lg"></i> Telecharger</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2>Ventes <small></small></h2>
        <div class="row">
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Ventes</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getSalesRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="salesDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="salesDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  v-model="salesDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Retours</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getSalesReturnRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="salesReturnDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>


                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="salesReturnDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  v-model="salesReturnDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Impayés</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getUnpaidSalesRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="unpaidSalesDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="unpaidSalesDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>


                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  v-model="unpaidSalesDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="bg-grey-lighter"/>

        <h2>Encaissements <small></small></h2>
        <div class="row">
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Règlements</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getPaymentRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="paymentsDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>


                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="paymentsDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  v-model="paymentsDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'" type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Recouvrements</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getClientPaymentRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="clientPaymentsDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>


                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="clientPaymentsDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  :disabled="true"
                                  v-model="clientPaymentsDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'" type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Accomptes Clients</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getClientPlacementRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="clientPlacementsDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>


                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="clientPlacementsDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  :disabled="true"
                                  v-model="clientPlacementsDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'" type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Ristournes Clients</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getClientBonusRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="clientBonusDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>


                    <div class="form-group row row-space-10">
                      <label class="col-label">Site Financier</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.inventory_codes"
                                  :reduce="item => item.code"
                                  :disabled="true"
                                  v-model="clientBonusDownloadFormData.inventory_codes" multiple/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Point de vente</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses"
                                  :reduce="item => item.id"
                                  :disabled="true"
                                  v-model="clientBonusDownloadFormData.warehouse_ids" multiple/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'" type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="bg-grey-lighter"/>

        <h2>Caisses <small></small></h2>
        <div class="row">
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Tous les mouvements</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getSafeOrderAllRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="safeOrderAllDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Caisse</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  v-model="safeOrderAllDownloadFormData.safe_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Entrées Libres</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getSafeOrderInRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="safeOrderInDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Caisse</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  v-model="safeOrderInDownloadFormData.safe_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Sorties Libres</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getSafeOrderOutRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="safeOrderOutDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Caisse</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  v-model="safeOrderOutDownloadFormData.safe_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Transferts</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getSafeOrderTransferRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="safeOrderTransferDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Origine</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  v-model="safeOrderTransferDownloadFormData.safe_id_origin"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  v-model="safeOrderTransferDownloadFormData.safe_id_destination"/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="bg-grey-lighter"/>

        <h2>Dépenses <small></small></h2>
        <div class="row">
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Dépenses</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getExpensesRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="expensesDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Caisse</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  v-model="expensesDownloadFormData.safe_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.safes_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">

          </div>
          <div class="col-3">

          </div>
          <div class="col-3">

          </div>
        </div>
        <hr class="bg-grey-lighter"/>

        <h2>Stock <small></small></h2>
        <div class="row">
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Tous les mouvements</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getStockOrderAllRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="stockOrderAllDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Emplacement</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  v-model="stockOrderAllDownloadFormData.warehouse_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Entrées Libres</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getStockOrderInRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="stockOrderInDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Emplacement</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  v-model="stockOrderInDownloadFormData.warehouse_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Sorties Libres</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getStockOrderOutRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="stockOrderOutDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Emplacement</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  v-model="stockOrderOutDownloadFormData.warehouse_id"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  :disabled="true"
                        />
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Transferts</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getStockOrderTransferRecordsDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <label>Définir la période</label>
                        <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                           opens="right"
                                           :ranges="dateRanges"
                                           :locale-data="{
                                                              format: 'dd-mm-yyyy',
                                                              separator: ' - ',
                                                              applyLabel: 'Appliquer',
                                                              cancelLabel: 'Annuler',
                                                              weekLabel: 'S',
                                                              customRangeLabel: 'Plage Perso.',
                                                              daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                              firstDay: 1
                                                            }"
                                           :singleDatePicker="false"
                                           :timePicker="false"
                                           :timePicker24Hour="true"
                                           :showWeekNumbers="false"
                                           :showDropdowns="false"
                                           :autoApply="true"
                                           v-model="stockOrderTransferDownloadFormData.date_range"
                                           @update="()=>{}"
                                           :linkedCalendars="false">
                          <template v-slot:input="picker" class="text-right">
                            {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                            <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                          </template>
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Origine</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  v-model="stockOrderTransferDownloadFormData.warehouse_id_origin"/>
                      </div>
                    </div>

                    <div class="form-group row row-space-10">
                      <label class="col-label">Destination</label>
                      <div class="col-12">
                        <v-select label="name" :options="pageIndexApiData.warehouses_all"
                                  :reduce="item => item.id"
                                  v-model="stockOrderTransferDownloadFormData.warehouse_id_destination"/>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="bg-grey-lighter"/>

        <h2>Inventaires <small></small></h2>
        <div class="row">
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Stock Réseau Bleu</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getInformativeInventoriesDownloadUrl">
                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <date-picker
                            v-model="informativeInventoriesDownloadFormData.date"
                            :config="{
                              format:'DD-MM-YYYY',
                              useCurrent:false
                            }"
                            placeholder="Choisir une date">
                        </date-picker>
                      </div>
                    </div>
                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4>Stock Réseau Rouge</h4>
              </div>
              <div class="panel-body">
                <div>
                  <form @submit.prevent="getCorrectiveInventoriesDownloadUrl">

                    <div class="form-group row row-space-10">
                      <div class="col-12">
                        <date-picker
                            v-model="correctiveInventoriesDownloadFormData.date"
                            :config="{
                              format:'DD-MM-YYYY',
                              useCurrent:false
                            }"
                            placeholder="Choisir une date">
                        </date-picker>
                      </div>
                    </div>

                    <button :disabled="documentDownloadUrlApiDataIsLoading || pusherPayload.status == 'loading'"
                            type="submit"
                            class="btn btn-block btn-success">Exporter
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">

          </div>
          <div class="col-3">

          </div>
        </div>
      </div>

    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import axios from 'axios'
import pageApi from '../../store/api/admin/excel-exports-page-api'
import LocalStorage from "../../store/local-storage";
import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'


export default {
  name: 'excel-exports-page-component',
  components: {
    CompanyLicenceComponent,
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    listenToServerBroadcastChannel() {
      let user = LocalStorage.getUserInfo();
      if (user.id != null && user.company_id != null) {
        window.Echo.channel(window.appConfig.pusherBeamsChannelPrefix + '-document-download-ready-private-broadcast-channel-' + user.company_id + '-' + user.id)
            .listen('DocumentDownloadLinkReadyPrivateEvent', (e) => {
              this.pusherPayload = e.pusher_payload;
            });
      }
    },

    formatDateInput (value) {
      if (value) {
        return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
      }
      return null;
    },

    //Other Methods
    async getPageIndexApiData() {
      this.pageIndexApiDataIsLoading = true;
      this.pageIndexApiDataErrorMessage = null;
      try {
        const response = await pageApi.getIndexData();
        //console.log(response);
        this.pageIndexApiData = response.data;
        this.pageIndexApiDataIsLoading = false;
        this.pageIndexApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            LocalStorage.clearSessionData();
            this.$router.push('login');
          } else {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            //
            this.$swal({
              icon: 'error',//'info','success','warning','error','question',
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showConfirmButton: false,
              title: 'HTTP ' + error.response.status,
              text: error.response.data.message,
            })
          }
        } else {
          this.pageIndexApiDataIsLoading = false;
          this.pageIndexApiDataErrorMessage = error.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            //title: error.message
            text: error.message,
          })
        }
      }
    },

    async getSalesRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSalesRecordsDownloadUrl({
          date_from: this.formatDateInput(this.salesDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.salesDownloadFormData.date_range.endDate),
          inventory_codes: this.salesDownloadFormData.inventory_codes,
          warehouse_ids: this.salesDownloadFormData.warehouse_ids,
          email: this.salesDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getUnpaidSalesRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getUnpaidSalesRecordsDownloadUrl({
          date_from: this.formatDateInput(this.unpaidSalesDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.unpaidSalesDownloadFormData.date_range.endDate),
          inventory_codes: this.unpaidSalesDownloadFormData.inventory_codes,
          warehouse_ids: this.unpaidSalesDownloadFormData.warehouse_ids,
          email: this.unpaidSalesDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getSalesReturnRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSalesReturnRecordsDownloadUrl({
          date_from: this.formatDateInput(this.salesReturnDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.salesReturnDownloadFormData.date_range.endDate),
          inventory_codes: this.salesReturnDownloadFormData.inventory_codes,
          warehouse_ids: this.salesReturnDownloadFormData.warehouse_ids,
          email: this.salesReturnDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    async getPaymentRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getPaymentRecordsDownloadUrl({
          date_from: this.formatDateInput(this.paymentsDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.paymentsDownloadFormData.date_range.endDate),
          inventory_codes: this.paymentsDownloadFormData.inventory_codes,
          warehouse_ids: this.paymentsDownloadFormData.warehouse_ids,
          email: this.paymentsDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getClientPaymentRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getClientPaymentRecordsDownloadUrl({
          date_from: this.formatDateInput(this.clientPaymentsDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.clientPaymentsDownloadFormData.date_range.endDate),
          inventory_codes: this.clientPaymentsDownloadFormData.inventory_codes,
          warehouse_ids: this.clientPaymentsDownloadFormData.warehouse_ids,
          email: this.clientPaymentsDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getClientPlacementRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getClientPlacementRecordsDownloadUrl({
          date_from: this.formatDateInput(this.clientPlacementsDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.clientPlacementsDownloadFormData.date_range.endDate),
          inventory_codes: this.clientPlacementsDownloadFormData.inventory_codes,
          warehouse_ids: this.clientPlacementsDownloadFormData.warehouse_ids,
          email: this.clientPlacementsDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getClientBonusRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getClientBonusRecordsDownloadUrl({
          date_from: this.formatDateInput(this.clientBonusDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.clientBonusDownloadFormData.date_range.endDate),
          inventory_codes: this.clientBonusDownloadFormData.inventory_codes,
          warehouse_ids: this.clientBonusDownloadFormData.warehouse_ids,
          email: this.clientBonusDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    async getSafeOrderTransferRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSafeOrderTransferRecordsDownloadUrl({
          date_from: this.formatDateInput(this.safeOrderTransferDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.safeOrderTransferDownloadFormData.date_range.endDate),
          safe_id_origin: this.safeOrderTransferDownloadFormData.safe_id_origin,
          safe_id_destination: this.safeOrderTransferDownloadFormData.safe_id_destination,
          email: this.safeOrderTransferDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getSafeOrderInRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSafeOrderInRecordsDownloadUrl({
          date_from: this.formatDateInput(this.safeOrderInDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.safeOrderInDownloadFormData.date_range.endDate),
          safe_id: this.safeOrderInDownloadFormData.safe_id,
          email: this.safeOrderInDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getSafeOrderOutRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSafeOrderOutRecordsDownloadUrl({
          date_from: this.formatDateInput(this.safeOrderOutDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.safeOrderOutDownloadFormData.date_range.endDate),
          safe_id: this.safeOrderOutDownloadFormData.safe_id,
          email: this.safeOrderOutDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getSafeOrderAllRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSafeOrderAllRecordsDownloadUrl({
          date_from: this.formatDateInput(this.safeOrderAllDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.safeOrderAllDownloadFormData.date_range.endDate),
          safe_id: this.safeOrderAllDownloadFormData.safe_id,
          email: this.safeOrderAllDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    async getExpensesRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getExpensesRecordsDownloadUrl({
          date_from: this.formatDateInput(this.expensesDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.expensesDownloadFormData.date_range.endDate),
          safe_id: this.expensesDownloadFormData.safe_id,
          email: this.expensesDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    async getStockOrderTransferRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getStockOrderTransferRecordsDownloadUrl({
          date_from: this.formatDateInput(this.stockOrderTransferDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.stockOrderTransferDownloadFormData.date_range.endDate),
          warehouse_id_origin: this.stockOrderTransferDownloadFormData.warehouse_id_origin,
          warehouse_id_destination: this.stockOrderTransferDownloadFormData.warehouse_id_destination,
          email: this.stockOrderTransferDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getStockOrderInRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getStockOrderInRecordsDownloadUrl({
          date_from: this.formatDateInput(this.stockOrderInDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.stockOrderInDownloadFormData.date_range.endDate),
          warehouse_id: this.stockOrderInDownloadFormData.warehouse_id,
          email: this.stockOrderInDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getStockOrderOutRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getStockOrderOutRecordsDownloadUrl({
          date_from: this.formatDateInput(this.stockOrderOutDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.stockOrderOutDownloadFormData.date_range.endDate),
          warehouse_id: this.stockOrderOutDownloadFormData.warehouse_id,
          email: this.stockOrderOutDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    async getStockOrderAllRecordsDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getStockOrderAllRecordsDownloadUrl({
          date_from: this.formatDateInput(this.stockOrderAllDownloadFormData.date_range.startDate),
          date_to: this.formatDateInput(this.stockOrderAllDownloadFormData.date_range.endDate),
          warehouse_id: this.stockOrderAllDownloadFormData.warehouse_id,
          email: this.stockOrderAllDownloadFormData.email
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    async getInformativeInventoriesDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getInformativeInventoriesDownloadUrl({
          date: this.informativeInventoriesDownloadFormData.date,
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },
    async getCorrectiveInventoriesDownloadUrl() {

      this.pusherPayload.download_url = null;

      this.documentDownloadUrlApiDataIsLoading = true;
      this.documentDownloadUrlApiDataErrorMessage = null;
      this.documentDownloadUrlApiDataValidationErrors = [];
      try {
        const response = await pageApi.getCorrectiveInventoriesDownloadUrl({
          date: this.correctiveInventoriesDownloadFormData.date,
        });
        //console.log(response);
        this.documentDownloadUrlApiData = response.data;
        this.documentDownloadUrlApiDataIsLoading = false;
        this.documentDownloadUrlApiDataErrorMessage = null;
        this.documentDownloadUrlApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.documentDownloadUrlApiDataValidationErrors = error.response.data.errors;
          } else {
            this.documentDownloadUrlApiDataValidationErrors = [];
          }
        } else {
          this.documentDownloadUrlApiDataIsLoading = false;
          this.documentDownloadUrlApiDataErrorMessage = error.message;
          this.documentDownloadUrlApiDataValidationErrors = [];
        }
      }
    },

    //
    downloadDocumentFromUrl(url, fileName) {
      axios.get(url, {responseType: 'blob'})
          .then(response => {
            //console.log(response.data);

            let file = new Blob([response.data]);
            let fileURL = window.URL.createObjectURL(file);

            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(error => {
            //console.log(error.response.data.message);
            this.$swal({
              type: 'error',//'info','success','warning','error','question',
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showConfirmButton: false,
              title: error.response.data.message
              //text: error.response.data.message,
            })
          });
    },
  },
  data: function () {

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let todayEnd = new Date()
    todayEnd.setHours(11, 59, 59, 999);
    let yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0);
    let yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(11, 59, 59, 999);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);


    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      pusherPayload: {
        status: '',
        message: '',
        download_url: null
      },

      salesDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },
      unpaidSalesDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },
      salesReturnDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },

      paymentsDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },
      clientPaymentsDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },
      clientPlacementsDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },
      clientBonusDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        inventory_codes: [],
        warehouse_ids: [],
        email: ''
      },

      safeOrderAllDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        safe_id: null,
        email: ''
      },
      safeOrderTransferDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        safe_id_origin: null,
        safe_id_destination: null,
        email: ''
      },
      safeOrderInDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        safe_id: null,
        email: ''
      },
      safeOrderOutDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        safe_id: null,
        email: ''
      },

      expensesDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        safe_id: null,
        email: ''
      },

      stockOrderAllDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        warehouse_id: null,
        email: ''
      },
      stockOrderTransferDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        warehouse_id_origin: null,
        warehouse_id_destination: null,
        email: ''
      },
      stockOrderInDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        warehouse_id: null,
        email: ''
      },
      stockOrderOutDownloadFormData: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        warehouse_id: null,
        email: ''
      },

      informativeInventoriesDownloadFormData: {
        date: null,
      },
      correctiveInventoriesDownloadFormData: {
        date: null,
      },

      dateRanges: {
        "Aujourd'hui": [today, todayEnd],
        "Hier": [yesterdayStart, yesterdayEnd],
        "Mois en cours": [thisMonthStart, thisMonthEnd],
        "Mois dernier": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
        ],
        "Année en cours": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
        ],
        "Année dernière": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear() - 1, 11, 31, 11, 59, 59, 999)
        ],
      },

      //API Data
      //
      pageIndexApiData: null,
      pageIndexApiDataIsLoading: false,
      pageIndexApiDataErrorMessage: null,

      //
      documentDownloadUrlApiData: null,
      documentDownloadUrlApiDataIsLoading: false,
      documentDownloadUrlApiDataErrorMessage: null,
      documentDownloadUrlApiDataValidationErrors: [],
    };
  },
  created: function () {
    this.getPageIndexApiData();
  },
  mounted: function () {
    this.loadPageScripts();
    this.listenToServerBroadcastChannel();
  },
  beforeDestroy: function () {
  },
}
</script>
