/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/admin/exports/excel";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix+'/index');
    },

    getSalesRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-sales-records-download-url',data);
    },

    getUnpaidSalesRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-unpaid-sales-records-download-url',data);
    },

    getSalesReturnRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-sales-return-records-download-url',data);
    },

    getPaymentRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-payments-records-download-url',data);
    },

    getClientPaymentRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-client-payments-records-download-url',data);
    },

    getClientPlacementRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-client-placements-records-download-url',data);
    },

    getClientBonusRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-client-bonus-records-download-url',data);
    },

    getSafeOrderAllRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-safe-orders-all-download-url',data);
    },

    getSafeOrderInRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-safe-orders-in-download-url',data);
    },

    getSafeOrderOutRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-safe-orders-out-download-url',data);
    },

    getSafeOrderTransferRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-safe-orders-transfer-download-url',data);
    },

    getExpensesRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-expenses-records-download-url',data);
    },

    getStockOrderAllRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-stock-orders-all-download-url',data);
    },

    getStockOrderInRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-stock-orders-in-download-url',data);
    },

    getStockOrderOutRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-stock-orders-out-download-url',data);
    },

    getStockOrderTransferRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-stock-orders-transfer-download-url',data);
    },

    getInformativeInventoriesDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-informative-inventories-download-url',data);
    },

    getCorrectiveInventoriesDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-corrective-inventories-download-url',data);
    },
}
